<template>
  <div class="d-flex flex-column border border-1 rounded p-4">
    <h4>
      {{ $t('pages.identity.login.what_is_analytikal.title') }}
    </h4>

    <div class="mt-3 mb-4">
      {{ $t('pages.identity.login.what_is_analytikal.text') }}
    </div>

    <div>
      <ui-button
        size="sm"
        variant="outline-primary"
        href="https://analytikal.nl"
        target="_blank"
      >
        {{ $t('pages.identity.login.btn_view_more') }}
      </ui-button>
    </div>
  </div>
</template>
